@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}


.achievement-page.dark {
    background-color: black;
    color: white;
}

@media (max-width: 599px) {
    .achievement-page {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .achievement-page.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

.achievement-page-header {
    position: relative;
    text-align: left;
}

.achievement-page-header-image {
    width: 100%;
}

.achievement-page-header-content {
    position: absolute;
    bottom: 0;
    right: 100px;
    background: white;
    padding: 20px 40px;
    max-width: 450px;
}

.achievement-page-header-content.dark {
    position: absolute;
    bottom: 0;
    right: 100px;
    background: black;
    color: white;
    padding: 20px 40px;
    max-width: 450px;
}

.achievement-page-header-content h1 {
    font-size: 32px;
}

.achievement-page-header-content h2 {
    font-size: 24px;
}

.achievement-page-header-content h1,
.achievement-page-header-content h2 {
    font-family: "RalewayHeavy";
}

.achievement-page-header-content p {
    margin-top: 10px;
    font-family: "SegoeUI";
    font-size: 20px;
}

.achievement-page-links {
    padding: 0 80px;
}

.achievement-page-links h2 {
    margin-top: 0;
}

.achievement-page-role,
.achievement-page-design h2 {
    padding: 20px 80px;
}

.achievement-page-role ul {
    list-style: none;
    padding: 0;
}

.achievement-page-role li {
    margin: 10px 0;
}

.achievement-page-link-buttons {
    display: flex;
    margin-top: 40px;
    width: auto;
    gap: 30px;
}

.achievement-page-link-buttons a img {
    transition: transform 0.2s;
}

.achievement-page-link-buttons a img:hover {
    transform: scale(1.1);
}

.achievement-page-design-images {
    display: flex;
    margin-top: 10px;
    padding-bottom: 40px;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
}

.achievement-page-design-image {
    border-radius: 10px;
}

@media (max-width: 599px) {
    .achievement-page-header-content, .achievement-page-header-content.dark {
        position: relative;
        right: 0;
    }

    .achievement-page-role {
        padding: 0 40px;
    }

    .achievement-page-links {
        margin-top: 40px;
        padding: 0 40px;
    }

    .achievement-page-design h2 {
        padding: 0 40px;
    }

    .achievement-page-design-images {
        display: flex;
        flex-direction: column;
    }
}
  