@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.services {
    text-align: center;
}

@media (max-width: 599px) {
    .services {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .services.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

/* Services 1 ----------------------- */
.services-section1 {
    position: relative;
    padding: 0;
    margin: 0;
}

.services-agency-background {
    position: relative;
    width: 100%;
    height: 60vh;
    display: block;
    object-fit: cover;
    object-position: top;
}

.services-introduction {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.services-introduction.dark {
    background-color: black;
    color: white;
}

.services-introduction.light {
    background-color: white;
    color: black;
}

.services-introduction-title {
    margin: 0;
    font-family: "RalewayHeavy";
    font-size: 50px;
}

.services-introduction-body {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 35px;
    font-family: "SegeoUI";
}

@media (min-width: 600px) and (max-width: 1400px) {
    .services-introduction-body {
        font-size: 28px;
    }

    .services-introduction-title {
        font-size: 42px;
    }
}

@media (max-width: 599px) {
    .services-introduction {
        height: 40vh;
    }

    .services-introduction-body {
        font-size: 16px;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
    }

    .services-introduction-title {
        font-size: 30px;
    }

    .services-agency-background {
        object-fit: cover;
        object-position: right center;
    }

    .services-agency-background {
        position: relative;
        width: 100%;
        height: 50vh;
    }
}

/* Services 2 ----------------------- */

.services-section2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
}

.services-section2:hover .services-container {
    filter: brightness(40%);
    transition: filter 1s;
}

.services-section2 .services-container:hover {
    filter: brightness(100%);
    transform: scale(1.05);
    z-index: 1;
    transition: filter 0.5s, transform 0.5s;
}

.services-container {
    position: relative;
    overflow: hidden;
    width: 33.33%;
    height: 33.33vw;
    padding-top: 33.33%;
    margin: 0;
    padding: 0;
}

.services-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;    
}

.services-text {
    transition: font-size 0.5s ease, text-shadow 0.5s ease;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: "RalewayHeavy";
    font-size: 40px;
    text-align: center;
    z-index: 2;
    text-shadow: -1px -1px 30px rgb(0, 0, 0, 0.5),
                 1px -1px 30px rgb(0, 0, 0, 0.5),
                 -1px 1px 30px rgb(0, 0, 0, 0.5),
                 1px 1px 30px rgb(0, 0, 0, 0.5);
}

.services-container:hover .services-text {
    font-size: calc(46px *1.05);
    text-shadow: -1.1px -1.1px 30px rgb(0, 0, 0, 0.5),
                 1.1px -1.1px 30px rgb(0, 0, 0, 0.5),
                 -1.1px 1.1px 30px rgb(0, 0, 0, 0.5),
                 1.1px 1.1px 30px rgb(0, 0, 0, 0.5);
}



@media (max-width: 1000px) and (min-width: 600px)  {
    .services-text {
        font-size: 24px;
    }

    .services-container:hover .services-text {
        font-size: calc(24px * 1.05);
    }
}

@media (max-width: 599px) {
    .services-container {
        width: 50%;
        height: 50vw;
    }

    .services-text {
        font-size: 24px;
        text-shadow: -1px -1px 15px rgb(0, 0, 0, 0.4),
                 1px -1px 15px rgb(0, 0, 0, 0.4),
                 -1px 1px 15px rgb(0, 0, 0, 0.4),
                 1px 1px 15px rgb(0, 0, 0, 0.4);
    }

    .services-container:hover .services-text {
        font-size: 24px;
    }

    .services-section2:hover .services-container {
        filter: none;
        transform: none;
        transition: none;
    }

    .services-section2 .services-container:hover {
        filter: none;
        transform: none;
        z-index: auto;
        transition: none;
    }    
}

