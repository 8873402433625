@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

.page404-section {
    display: flex;
    align-items: center;
    padding: 0;
    padding-top: 10%;
    font-family: "SegoeUI";
    font-size: 32px;
}

.page404-section.dark {
    background-color: black;
}

.page404-section.light {
    background-color: white;
}

.page404-section .page404-left-picture {
    flex: 0 0 50%;
}

.page404-section .page404-left-picture img {
    margin-left: 10%;
    max-height: 80vh;
    max-width: 33vw;
}

.page404-section .page404-right-content {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.page404-section .page404-right-content h1 {
    color: #566FA7;
    margin: 0;
    font-size: 96px;
    padding-right: 10%;
}

.page404-section .page404-right-content p {
    color: #8093B8;
    margin: 0;
    font-size: 48px; 
    padding-right: 20%; 
}

.page404-back-home-button {
    border: none;
    padding: 20px 30px;
    text-decoration: none;
    color: inherit;
    border-radius: 50px;
    display: inline-block;
    color: white;
    background-color: #566FA7;
    font-size: 32px;
    max-width: 40%;
    text-align: center;
}