.work-in-progress {
    position: relative;
}

@media (max-width: 599px) {
    .work-in-progress {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .work-in-progress.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

.agency-background-work-in-progress {
    position: relative;
    width: 100%;
    height: 70vh;
    display: block;
    object-fit: cover;
    overflow: hidden;    
    object-position: top;
}

.work-in-progress-container {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    text-shadow: 1px 1px 1px black;
}

.work-in-progress-text {
    font-size: 10ch;
    margin-bottom: 0;
}