@font-face {
    font-family: "SegoeUI";
    src: url("../../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.navbar-mobile {
    display: none;
}

.navbar-desktop {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 15%;
    z-index: 1000;
    background-color: transparent;
    transition: opacity 0.5s ease;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
}

.navbar-logo {
    height: 120px;
}

.navbar-logo img {
    width: 100%;
    height: 100%;
}

.navbar-center-links {
    height: 100%;
    display: flex;
    align-items: center;    
    margin-right: auto;
    margin-left: auto;
}

.navbar-link {
    margin: 0 20px;
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-family: "SegoeUI",sans-serif;
}

.navbar-right-icons {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 106px;
    gap: 10px;
}

.navbar-right-icons .navbar-link, .navbar-right-icons .navbar-theme-toogle {
    margin: 0 30px;
}

.navbar-theme-toogle {
    cursor: pointer;
}

.navbar-theme-toogle img {
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1);
}

.navbar-icon {
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1);
}

.navbar-language-selector-container {
    position: relative;
    cursor: pointer;
}

.navbar-language-icon{
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1); 
}

.navbar-language-selector-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid #ccc;
    background-color: #ffffff;
}

.navbar-flag-icon {
    width: 33px;
    height: 20px;  
    margin-right: 5px;
}

.navbar-language-option {
    display: flex;
    padding: 10px;
    color: black;
    font-family: "SegoeUI",sans-serif;
    cursor: pointer;
}

.navbar-language-option:hover {
    background-color: #f0f0f0;
}

/* Others screen ------------------------ */

/* Mobile ---------------------------- */
@media (max-width: 599px) {
    body.menu-open {
        position: fixed;
        width: 100%;
        top: calc(-1 * var(--scroll-y));
    }
    
    .navbar-desktop {
        display: none;
    }

    .navbar-mobile {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: transparent;
        transition: opacity 0.5s ease;
        font-family: "RalewayHeavy";
        font-size: 22px;
    }

    .navbar-menu-icon {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 2;
        display: inline-flex;
        background-color: none;
        cursor: pointer;
        width: 100px;
        height: 100px;
    }

    .navbar-menu-icon.dark {
        background-color: none;
    }

    .navbar-menu-icon.light {
        background-color: none;
    }

    .navbar-hamburger-icon {
        display: inline-flex;
        width: 65%;
        height: 65%;  
        margin-right: 5px;
        color: white;
    }

    .navbar-hamburger-icon.dark {
        fill: white;
    }

    .navbar-hamburger-icon.light {
        fill: #333;
    }
    
    .navbar-sidebar {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        top: 0;
        z-index: 1;
        left: -100vw;
        width: 100vw;
        height: 100%;
        padding-top: 60px;
        color: white;
        background-color: #333;
        transition: left 0.3s ease;
    }

    .navbar-sidebar.active {
        overflow: hidden;
        transform: translateX(80%);
        transition: transform 0.3s ease;
    }

    .navbar-sidebar.dark {
        color: white;
        background-color: #333;
    }

    .navbar-sidebar.light {
        color: black;
        background-color: white;
    }

    .navbar-sidebar-link {
        display: inline-block;
        padding: 10px;
        padding-left: 30%;
        padding-bottom: 20px;
        text-decoration: none;
        color: inherit;
    }

    .navbar-sidebar-link:first-child {
        margin-top: 40px;
    }

    .navbar-mobile-english-flag-icon {
        width: 33px;
        height: 20px;  
    }

    .navbar-mobile-french-flag-icon {
        width: 33px;
        height: 20px;  
    }

    .navbar-mobile-theme-toogle img {
        width: 33px;
        height: 20px;
        filter: brightness(0) invert(1);        
        padding-left: 10px;
    }

    .navbar-mobile-language-container {
        display: flex;
        flex-direction: row;
        padding-left: 30%;
        padding-bottom: 20px;
        padding-top: 10px;
    }

    .navbar-mobile-language-option {
        cursor: pointer;
        margin-right: 20px;
    }

    .navbar-mobile-language-option img {
        border-radius: 4px;
    }

    .navbar-mobile-theme-toogle {
        display: flex;
        cursor: pointer;
        padding-left: 30%;
    }
}