@font-face {
    font-family: "Raleway";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "AssistantRegular";
    src: url("../../font/Assistant_Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "AssistantBold";
    src: url("../../font/Assistant_Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Slick";
    src: url("../../font/external_slick.woff") format("woff");
    font-display: swap;
}

.home {
    text-align: center;
}

@media (max-width: 599px) {
    .home {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .home.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

/*
@media (max-width: 599px) {
    .home {
        transition: transform 0.3s ease;
        transform: translateX(0%) scale(1);
    }
    
    .home.menu-open {
        transform: translateX(80%) scale(0.9);
        transition: transform 0.3s ease;
        max-height: 90vh;
        overflow-y: auto;
        margin-top: 5vh;
        margin-bottom: 5vh;
        border-radius: 20px;
    }
}

/* Section 1 ----------------------- */
.home-section1 {
    position: relative;
    width: 100%;
}

.home-video-background {
    position: relative;
    overflow: hidden;
    height: 100vh;
}

.home-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-agency-top-text {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    transition: none;
    text-align: center;
    color: white;
    text-shadow: -1px -1px 30px rgb(0, 0, 0, 0.2),
                 1px -1px 30px rgb(0, 0, 0, 0.2),
                 -1px 1px 30px rgb(0, 0, 0, 0.2),
                 1px 1px 30px rgb(0, 0, 0, 0.2);
}

.home-agency-top-text-title {
    margin-bottom: 0;
    font-family: "Raleway";
    font-size: 40px;    
}

.home-agency-top-text-body {
    font-size: 14px;
    margin-top: 10px;
    font-family: "SegoeUI";
}

.home-agency-bottom-icons {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    transition: none;
    display: flex;
    gap: 20px;
    width: 30%;
    color: white;
}

.home-agency-bottom-icons div {
    flex: 1;
    text-align: center;
}

.home-agency-bottom-icons div img{
    width: 30px;
    height: 30px;
    margin-bottom: 0;
}

.home-agency-bottom-icons div p{
    margin-top: 0;
    font-size: 16px;
    text-shadow: -1px -1px 30px rgb(0, 0, 0, 0.2),
                 1px -1px 30px rgb(0, 0, 0, 0.2),
                 -1px 1px 30px rgb(0, 0, 0, 0.2),
                 1px 1px 30px rgb(0, 0, 0, 0.2);
}

@keyframes animationCreativity {
    0% {
        transform: translate(0, 0) rotateZ(0);
    }
    100% {
        transform: translate(0, 0) rotateZ(360deg);
    }
}

.home-creativity-icon:hover path{
    transform-origin: center;
    animation: animationCreativity 2s infinite linear;
    fill: rgb(207, 167, 22);
}

@keyframes animationEngagement {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.1);
    }
}

.home-engagement-icon:hover path{
    transform-origin: center;
    animation: animationEngagement 1s infinite;
    fill: rgb(28, 17, 226);
}

@media (min-width: 600px) and (max-width: 1600px) {
    .home-agency-bottom-icons {
        width: 40%;
        font-size: 16px;
    }

    .home-agency-top-text-title {
        font-size: 38px;    
    }
}

@media (max-width: 599px) {
    .home-agency-bottom-icons {
        width: 60%;
        font-size: 16px;
    }

    .home-agency-top-text-title {
        font-size: 32px;
        width: 100vw;
        text-shadow: -1px -1px 10px rgb(0, 0, 0, 0.2),
                 1px -1px 10px rgb(0, 0, 0, 0.2),
                 -1px 1px 10px rgb(0, 0, 0, 0.2),
                 1px 1px 10px rgb(0, 0, 0, 0.2);
    }

    .home-agency-top-text-body {
        font-size: 14px;
        margin-top: 5px;
        text-shadow: -1px -1px 10px rgb(0, 0, 0, 0.2),
        1px -1px 10px rgb(0, 0, 0, 0.2),
        -1px 1px 10px rgb(0, 0, 0, 0.2),
        1px 1px 10px rgb(0, 0, 0, 0.2);
    }
}

/* Section 2 ----------------------- */
.home-section2 {
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
    height: 100%;
    width: 100%;
    font-family: "SegoeUI";
}

.home-section2 picture {
    width: 100%;
    height: 100%;
}

.home-section2.dark {
    background-color: black;
    color: white;
}

.home-section2.light {
    /*background: linear-gradient(to top, black, white 20%);*/
    color: black;
}

.home-section2.light::before {
    content: '';
    position: absolute; 
    bottom: 0; 
    left: 0;
    right: 0;
    height: 15%; 
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    z-index: 2; 
}

.home-services-background {
    width: 50%;
    height: 100%; 
    padding-right: 10%;
    position: relative;
    z-index: 1;
}

.home-services-text-and-button {
    padding-left: 40px;
    padding-bottom: 40px;
}

.home-services-title {
    font-size: 28px;
    margin-bottom: 5%;
}

.home-services-body {
    font-size: 16px;
    margin-bottom: 5%;
}

.home-services-button {
    border: 2px solid black;
    padding: 10px 20px;
    text-decoration: none;
    color: inherit;
    border-radius: 5px;
    display: inline-block;
}

.home-services-button.dark {
    border: 2px solid white;
}

.home-services-button.light {
    border: 2px solid black;
}


@media (min-width: 1000px) and (max-width: 1600px) {
    .home-services-title {
        margin-bottom: 3%;
    }
    
    .home-services-body {
        font-size: 14px;
        margin-bottom: 3%;
    }

    .home-services-background {
        padding-right: 5%;
        width: 70%;
    }

    .home-section2.light {
        background: linear-gradient(to top, black, white 10%);
    }

    .home-services-button {
        font-size: 14px;
    }
}

@media (max-width: 599px) {
    
    @media (min-height: 800px) {
        .home-section2 {
            height: 45vh;
        }
    }

    @media (max-height: 799px) {
        .home-section2 {
            height: 60vh;
        }
    }

    .home-services-text-and-button {
        padding: 10px;
        height: 100%;
    }

    .home-services-title {
        font-size: 24px;
        margin-bottom: 0%;
        margin-top: 10%;
    }
        
    .home-services-body {
        font-size: 16px;
        margin-bottom: 8%;
        padding-left: 10px;
        text-align: left;
    }

    .home-services-background {
        display: none;
    }

    .home-services-button {
        font-size: 16px; 
    }

}

/* Section Slide ----------------------- */
.home-section-slide {
    height: 20vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: black;
    color: white;
}

.home-slide {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    white-space: nowrap;
    align-items: center;
    animation: slide 30s linear infinite;
    font-size: 65px;
    font-family: "Raleway";
}

.home-slide-text-empty {
    display: inline-block;
    position: relative;
    text-shadow: -1px -1px 0 #DCB253,
    1px -1px 0 #DCB253,
    -1px 1px 0 #DCB253,
    1px 1px 0 #DCB253;
    color: black;
}

.home-slide-text-empty::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 0px;
    background-color: #DCB253;
    -webkit-background-clip: text;
    background-clip: text;
}

.home-slide-text-full {
    color: #DCB253;
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-419%);
    }
}

@keyframes slideMobile {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-1200%);
    }
}

@media (min-height: 1000px) and (min-width: 1700px) {
    .home-slide {
        font-size: 90px;
    }
}

@media (max-width: 1000px) and (min-width: 600px) {    
    .home-slide {
        font-size: 50px;
    }
    
}

@media (max-width: 599px) {
    .home-section-slide {
        height: 15vh;
    }

    .home-slide {
        font-size: 40px;
        animation: slideMobile 34s linear infinite;
    }
}

/* Section 3 ----------------------- */
.home-section3 {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: "AssistantRegular";
}

.home-section3.dark{
    background-color: black;
    color: white;
}

.home-section3.light{
    background-color: white;
    color: dark;
}

.home-contact-info {
    width: 50%;  
    height: 100%;
    display: flex;
    flex-direction: column;
}

.home-contact-text {
    flex: 1;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.home-contact-title {
    margin-bottom: 30px;
    margin-left: 40px;
    margin-right: 60px;
    font-size: 65px;
    font-family: "AssistantBold";
}

.home-button-wrapper {
    width: fit-content;
}

.home-contact-button {
    background-color: #c7a24b;
    color: white;
    border: none;
    font-size: 22px;
    align-self: flex-start;
    padding: 20px 40px;
    margin-left: 40px;    
    cursor: pointer;
    font-family: "AssistantRegular";
}

.home-box-container {
    flex: 1;
    bottom: 0;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.home-box-design {
    max-height: 100%;
    width: 25%;
    border-Top: 3px solid red;
    align-self: flex-end;
    text-align: left;
    padding-left: 1%;
    padding-right: 1%;
}

.home-box-design.dark{
    background-color: rgb(34, 34, 34);
    color: white;
}

.home-box-design.light{
    background-color: rgb(247, 247, 247);
    color: dark;
}

.home-box-digital {
    max-height: 100%;
    width: 25%;
    border-Top: 3px solid blue;
    align-self: flex-end;
    text-align: left;
    padding-left: 1%;
    padding-right: 1%;
}

.home-box-digital.dark{
    background-color: rgb(34, 34, 34);
    color: white;
}

.home-box-digital.light{
    background-color: rgb(247, 247, 247);
    color: dark;
}

.home-box-development {
    max-height: 100%;
    width: 30%;
    border-Top: 3px solid green;
    align-self: flex-end;
    margin-right: 10%;
    text-align: left;
    padding-left: 1%;
    padding-right: 1%;
}

.home-box-development.dark{
    background-color: rgb(34, 34, 34);
    color: white;
}

.home-box-development.light{
    background-color: rgb(247, 247, 247);
    color: dark;
}

.home-box-container p {
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 2vh;
}

.home-box-container h3 {
    font-size: 2vh;
    padding-left: 20px;
    padding-right: 20px;
}

.home-box-container div img {
    width: 45px;
    height: 45px;
    display: block;
    margin-top: 1vh;
    padding-left: 20px;
}

.home-arrow-icon{
    float: right;
    margin-right: 1vh;
    margin-bottom: 1vh;
}

.home-contact-visual {
    width: 50%;
    border: none;
}

.home-slider {
    width: 100%;
    outline: none;
}

.home-contact-visual div{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.home-contact-visual div picture img {
    width: 50vw;
    height: 95vh;
}

@media (max-width: 1600px) and (min-width: 1450px) {
    .home-contact-title {
        font-size: 54px;
        margin-top: 30px;
    }

    .home-box-container p {
        padding-bottom: 0px;
    }

    .home-box-container div img {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 1449px) and (min-width: 1200px) {
    .home-contact-title {
        font-size: 48px;
        margin-top: 10px;
    }

    .home-box-container p {
        padding-bottom: 0;
        margin-bottom: 0
    }

    .home-box-container h3 {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .home-arrow-icon img {
        margin-top: 0 !important;
    }

    .home-box-container div img {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 1199px) and (min-width: 600px) {
    .home-contact-title {
        font-size: 42px;
        margin-top: 10px;
        margin-left: 30px;    
    }

    .home-contact-button {
        background-color: #DCB253;
        color: white;
        border: none;
        font-size: 22px;
        align-self: flex-start;
        padding: 15px 30px;
        margin-left: 30px;
        cursor: pointer;
        font-family: "AssistantRegular";
    }

    .home-box-container p {
        padding-bottom: 0;
        margin-bottom: 0
    }

    .home-box-container h3 {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .home-arrow-icon img {
        margin-top: 0 !important;
    }

    .home-box-container div img {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 599px) {
    @media (min-height: 800px) {
        .home-section3 {
            display: flex;
            height: 70vh;
        }

        .home-contact-title {
            font-size: 28px;
            margin-right: 20px;
        }

        .home-contact-button {
            font-size: 18px;
            padding: 15px 20px; 
        }
    }

    @media (max-height: 799px) {
        .home-section3 {
            display: flex;
            height: 90vh;
        }

        .home-box-container div img {
            width: 35px;
            height: 35px;
            margin-top: 10px;
            padding-left: 20px;
        }

        .home-box-container h3 {
            font-size: 22px;
            margin-top: 5px;
        }

        .home-contact-title {
            font-size: 26px;
            margin: 20px;
        }

        .home-contact-button {
            font-size: 10px;
            padding: 10px 16px; 
            margin-left: 15px;
        }
    }

    .home-contact-info {
        width: 100%;
        display: flex;  
    }

    .home-contact-visual {
        display: none;
    }

    .home-box-container p {
        padding-bottom: 0;
        margin-bottom: 0;
        font-size: 14px;
    }

    .home-box-container h3 {
        font-size: 20px;
    }

    .home-contact-text {
        position: absolute;
        max-height: 35%;
    }

    .home-contact-button {
        background-color: #b4903d;
        font-size: 18px;
        padding: 15px 20px; 
    }
    /**/
    .home-box-design p, .home-box-design h3, .home-box-design img, 
    .home-box-digital p, .home-box-digital h3, .home-box-digital img,
    .home-box-development p, .home-box-development h3, .home-box-development img  {
        display: none !important;
    }

    .home-box-container {
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .home-box-design {
        border-top: 20px solid red;
        border-top-right-radius: 10px;
        width: 33%;
        transition: all 0.5s ease;
    }

    .home-box-design.open {
        border-Top: 5px solid red;
        width: 100%;        
    }

    .home-box-design.open p, .home-box-design.open h3, .home-box-design.open img {
        display: block !important;
    }

    .home-box-digital {
        border-top: 20px solid blue;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 33%;
        transition: all 0.5s ease;
    }

    .home-box-digital.open {
        border-Top: 5px solid blue;
        width: 100%;        
    }

    .home-box-digital.open p, .home-box-digital.open h3, .home-box-digital.open img {
        display: block !important; 
    }

    .home-box-development {
        border-top: 20px solid green;
        border-top-left-radius: 10px;
        width: 33%;
        margin-right: 0;
        transition: all 0.5s ease;
    }

    .home-box-development.open {
        border-Top: 5px solid green;
        width: 100%;        
    }

    .home-box-development.open p, .home-box-development.open h3, .home-box-development.open img {
        display: block !important;
    }
}

/* Section 4 ----------------------- */

.home-section4 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    font-family: "SegoeUI";
    font-size: 32px;
    height: 100vh;
}

.home-section4.dark {
    background-color: black;
}

.home-section4.light {
    background-color: white;
}

.home-section4 .home-left-newsletter {
    flex: 0 0 50%;
    padding-left: 20px;
}

.home-section4 .home-left-newsletter img {
    max-width: 80%;
    height: auto;
}

.home-section4 .home-right-newsletter {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
}

.home-section4 .home-right-newsletter h2 {
    color: #858C94;
    margin: 0;
    font-size: 32px;
    padding-left: 10px;
    padding-right: 80px;
}

.home-section4 .home-right-newsletter p {
    color: #394452;
    padding-left: 10px;
    padding-right: 80px;    
}

.home-newsletter-input {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0;
    margin-top: 20px;
    padding-left: 10px;
}

.home-newsletter-input input[type="email"] {
    padding: 10px 15px;
    padding-right: 80px;
    border: none;
    border-radius: 15px;
    outline: none;
    background-color: #F4F6F9;
    color: white;
    transition: background-color 0.3s ease;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-family: "SegoeUI";
    font-size: 18px;
}

.home-newsletter-input input[type="email"]:focus {
    background-color: #d9dfe7;
}

.home-newsletter-input input[type="email"]::placeholder {
    color: #737373;  
    font-family: "SegoeUI";
    font-size: 18px;
}

.home-newsletter-input button {
    padding: 10px 15px;
    border: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #000E15;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "SegoeUI";
    font-size: 18px;
}

.home-newsletter-input button:hover {
    background-color: #023650;
}

.home-newsletter-input button:focus {
    outline: none;
}

@media (max-width: 1500px) and (min-width: 600px) {
    .home-section4 .home-right-newsletter h2 {
        font-size: 22px;
        padding-right: 40px;
    }
    
    .home-section4 .home-right-newsletter p {
        font-size: 22px;
        padding-right: 40px;    
    }
}


@media (max-width: 599px) {
    .home-section4 {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        font-size: 20px;
        min-height: 800px;
    }

    .home-section4 .home-left-newsletter {
        flex: 1;
        padding-left: 0;
    }

    .home-section4 .home-left-newsletter img {
        max-width: 100%;
    }
    
    .home-section4 .home-right-newsletter {
        flex: 1;
        padding-left: 0;
    }

    .home-section4 .home-right-newsletter h2 {
        font-size: 16px;
        padding: 0 20px;
    }
    
    .home-section4 .home-right-newsletter p {
        font-size: 16px;
        padding: 0 20px;    
    }

    .home-newsletter-input {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
        align-items: stretch;
        padding: 10px 20px;
        margin: 0;
    }

    .home-newsletter-input input[type="email"], .home-newsletter-input button {
        border-radius: 5px;
    }
}


