@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.article-left-container img {
    width: 100%;
}

.article-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "SegeoUI";
}

@media (max-width: 599px) {
    .article-page {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .article-page.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}
  
.article-header-image {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "RalewayHeavy";
    color: white;
    text-shadow: -1px -1px 5px rgb(0, 0, 0, 0.0),
                 1px -1px 5px rgb(0, 0, 0, 0.0),
                 -1px 1px 5px rgb(0, 0, 0, 0.3),
                 1px 1px 5px rgb(0, 0, 0, 0.3);
}

.article-header-image h1 {
    font-size: 60px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
}
  
.article-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.article-content.dark {
    background-color: black;
    color: white;
}

.article-content.light {
    background-color: white;
    color: black;
}
  
.article-left-container {
    padding: 40px;
    max-width: 65%;
    box-sizing: border-box;
}

@media (max-width: 599px) {
    .article-content {
        display: flex;
        flex-direction: column;
    }

    .article-author-container {
        order: -1;
    }
}

@media (max-width: 1199px) {
    .article-page {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .article-page.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }

    .article-header-image h1 {
        font-size: 20px;
        max-width: 90%;
        margin-top: 100px;
    }

    .article-left-container {
        padding: 40px;
        padding-top: 0;
        max-width: 100%;
        box-sizing: border-box;    
    }

    .article-left-container img {
        max-width: 100%;
        height: 100%;
    }
}