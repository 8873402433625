@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.contact {
    display: flex;
    position: relative;
    flex-direction: column;
}

@media (max-width: 599px) {
    .contact {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .contact.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

.contact.dark {
    background-color: black;
}

.contact.light {
    background-color: white;
}

.contact-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    display: flex;
}

.contact-header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.contact-header-title {
    z-index: 1;
    color: white;
    font-size: 60px;
    margin-top: 7%;
    margin-bottom: 0;
    font-family: "RalewayHeavy";
    text-shadow: -1px -1px 30px rgb(0, 0, 0, 0.3),
                 1px -1px 30px rgb(0, 0, 0, 0.3),
                 -1px 1px 30px rgb(0, 0, 0, 0.3),
                 1px 1px 30px rgb(0, 0, 0, 0.3);
}

.contact-header-text {
    z-index: 1;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 2%;
    margin-bottom: 5%;
    color: white;
    font-size: 40px;
    font-family: "SegeoUI";
    text-shadow: -1px -1px 30px rgb(0, 0, 0, 0.3),
                 1px -1px 30px rgb(0, 0, 0, 0.3),
                 -1px 1px 30px rgb(0, 0, 0, 0.3),
                 1px 1px 30px rgb(0, 0, 0, 0.3);
}

.contact-container {
    margin: 5%;
    left:45%;
    transform: translateX(-50%);
    position: relative;   
    width: 90%;
    border-radius: 10px;
    display: flex;
}

.contact-container.dark {
    color: white;
    background-color: rgb(34, 34, 34);
    border-color: black;
}

.contact-container.light {
    color: black;
    background-color: #3636380D;
}

.contact-left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    background-color:#011C2A;
    border-radius: 10px;
    color: white;
    overflow: hidden;
}

.contact-title {
    padding-left: 10%;
    margin-top: 50px;
    margin-bottom: 20%;
    font-size: 35px;
}

.contact-fields-container {
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    justify-content: center;
    flex-grow: 1;
    padding-bottom: 5%;
    font-family: "SegoeUI";
}

.contact-field {
    display: flex;
    margin-top: 5%;
    padding-bottom: 5%;
    font-size: 25px;
}

.contact-field img {
    padding-right: 3%;
    width: 28px;
    height: 24px;
}

.contact-field a {
    text-decoration: none;
    color: inherit;
}

.contact-field a:hover, .contact-field a:focus {
    text-decoration: underline;
}

.contact-icons-container {
    display: flex;
    gap: 20px;
    padding-left: 5%;
    padding-bottom: 2%;
    margin-top: 20%;
}

.contact-right-container {
    width: 60%;
    font-family: "SegoeUI";
    background-color: #3636380D;
    border-radius: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 8%;
    padding-right: 3%;
}

.contact-form p {
    margin-top: 50px;
}

.contact-send-captcha {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 10px;
}

.contact-input-row {
    display: flex;
    gap: 50px;
    width: 90%;
    font-size: 20px;
    padding-top: 50px;
}

.contact-input-wrapper {
    position: relative;
    flex: 1;
}

.contact-input-wrapper label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    transition: top 0.3s, font-size 0.3s;
    font-size: 16px;
    color: #777;
    pointer-events: none;
}


.contact-input-wrapper.dark label {
    color: white;
}

.contact-input-wrapper input, .contact-input-wrapper textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #333;
    padding: 10px 5px;
    font-size: 16px;
    outline: none;
}

.contact-input-wrapper.dark input, .contact-input-wrapper.dark textarea {
    color: white;
}

.contact-input-wrapper input, .contact-input-wrapper textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #333;
    padding: 10px 5px;
    font-size: 16px;
    outline: none;
}


.contact-input-wrapper input:focus, .contact-input-wrapper textarea:focus {
    border-bottom-color: #007BFF;
}

.contact-input-wrapper input:focus + label,
.contact-input-wrapper input:not(:placeholder-shown) +label,
.contact-input-wrapper textarea:focus + label,
.contact-input-wrapper textarea:not(:placeholder-shown) +label{
    top: 0;
    font-size: 12px;
}

.justify-end {
    width: 95%;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.contact-button {
    background-color: #011C2A;
    color: white;
    margin-top: 5%;
    padding: 20px 60px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-family: "SegoeUI";
}

.contact-button:hover {
    background-color: #005080;
    transform: scale(1.05);
}

.contact-button:active {
    transform: scale(0.95);
}

.contact-radio-group {
    display: flex;
    flex-direction: row;
    font-size: 12px;

}

.contact-radio-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contact-radio-option input[type="radio"] {
    margin-right: 5px;
}

textarea {
    resize: none;
    background-color: transparent;
}

input {
    background-color: transparent;
}

.contact-icon-rs {
    width: 40px;
    height: 40px;
}

.contact-icon-bulle1 {
    position: absolute;
    top: 74%;
    right: 64%;
    width: 80px;
    height: 80px;
}

.contact-icon-bulle2 {
    position: absolute;
    top: 78%;
    right: 57%;
    width: 160px;
    height: 160px;
}

@media (max-width: 1600px) and (min-width: 1400px) {
    .contact-icon-bulle1 {
        top: 72%;
        right: 63%;
        width: 80px;
        height: 80px;
    }
    
    .contact-icon-bulle2 {
        top: 76%;
        right: 56%;
        width: 160px;
        height: 160px;
    }    
}

@media (max-width: 1399px) and (min-width: 600px) {
    .contact-icon-bulle1 {
        top: 75%;
        right: 64%;
        width: 60px;
        height: 60px;
    }
    
    .contact-icon-bulle2 {
        top: 80%;
        right: 58%;
        width: 120px;
        height: 120px;
    }    
}

@media (max-width: 599px) {
    .contact-header-title {
        font-size: 40px;
        margin-top: 15%;
    }

    .contact-header-text {
        font-size: 24px;
        text-align: center;
        margin-bottom: 15%;
    }

    .contact-container {
        flex-direction: column;
        display: flex;
        margin: 0;
        padding: 0;
        border-radius: 0;
        width: 100vw;
        left: 0;
        transform: translateX(0%);
        border: 0;
        height: 100%;
    }

    .contact-left-container {
        width: 100%;
        border-radius: 0;
        height: 40%;
    }

    .contact-title {
        padding-left: 0%;
        text-align: center;
        font-size: 28px;
        margin-bottom: 40px;
    }

    .contact-fields-container {
        padding-left: 5%;
        padding-bottom: 0%;
        justify-content: center;
    }

    .contact-field {
        margin-bottom: 5px;
        padding-bottom: 20px;
        font-size: 20px;
    }

    .contact-icons-container {
        display: flex;
        gap: 20px;
        padding-left: 0%;
        padding-bottom: 2%;
        margin-top: 40px;
        justify-content: center;        
    }

    .contact-right-container {
        width: 100%;
        border-radius: 0px;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 2%;
        padding-right: 5%;
        padding-bottom: 5%;
    }

    .contact-input-row {
        justify-content: center; 
        display: flex;
        gap: 50px;
        width: 100%;
        font-size: 20px;
        padding-top: 3%;
    }

    .contact-icon-bulle1 {
        display: none;
        top: 38%;
        right: -5%;
        width: 60px;
        height: 60px;
    }
    
    .contact-icon-bulle2 {
        display: none;
        top: 42%;
        right: -20%;
        width: 120px;
        height: 120px;
    }

    .contact-button {
        margin-left: 3%;
    }

}