@font-face {
    font-family: "SegoeUI";
    src: url("../../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.footer {
    width: 100%;
    color: white;
    background-color: #011C2A;
    font-family: "SegeoUI";
}

@media (max-width: 599px) {
    .footer.menu-open {
        display: none;
        height: 90vh;
        transform: translateX(80%) scale(0.9);
        transition: transform 0.3s ease;
    }
}

.footer-content {
    margin-left: 3%;
    margin-right: 3%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 94%;
}

.footer-contact-section {
    flex: 1;
    padding-left: 20px;
}

.footer-newsletter-section {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    background-color: #011621;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-info-section {
    display: flex;
    flex: 1;
}

.footer-contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
}

.footer-contact-item a, .footer-link {
    text-decoration: none;
    color: inherit;
}

.footer-contact-item img {
    height: 20px;
    width: 30px;
    padding-right: 10px;
}

.footer-info-block {
    margin-bottom: 20px;
    padding-right: 60px;
}

.footer-info-block ul {
    padding-left: 0;
}


.footer-info-block ul li {
    padding-top: 10px;
    margin-bottom: 11px;
    list-style-type: none;    
}


.footer-newsletter-section h2 {
    margin: 0;
}

.footer-newsletter-input {
    display: flex;
    gap: 0;
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: "SegoeUI";
}

.footer-newsletter-input input[type="email"] {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 18px;
    background-color: #022B3A;
    color: white;
    transition: background-color 0.3s ease;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.footer-newsletter-input input[type="email"]:focus {
    background-color: #033D52;
}

.footer-newsletter-input input[type="email"]::placeholder {
    color: rgb(92, 92, 92); 
    font-family: "SegoeUI";
}

.footer-newsletter-input button {
    padding: 10px 15px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 18px;
    background-color: #001214;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "SegoeUI";
}

.footer-newsletter-input button:hover {
    background-color: #002530;
}

.footer-newsletter-input button:focus {
    outline: none;
}

.footer-newsletter-note {
    font-size: 14px;
    margin-top: 10px;
    max-width: 340px;
}

.footer-logo {
    width: 20%;
    height: 100%;
    display: block;
    margin: 0 auto
}

.footer-newsletter-section h2, .footer-info-block h2, .footer-contact-section h2 {
    font-size: 20px;
    font-family: "RalewayHeavy";
}


@media (max-width: 599px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
        margin-left: 10px;
        padding-bottom: 10px;
        width: clac(100% - 20px);
    }

    .footer-contact-section, .footer-info-section  {
        width: 100%;
        padding-left: 0 !important;
        margin-bottom: 20px;
    }

    .footer-newsletter-section {
        width: 90%;
        padding-top: 10px;
    }

    .footer-contact-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-info-section {
        flex-direction: column;
        padding-left: 40px;
        border-top: 1px solid white;
    }

    .footer-newsletter-section h2, .footer-newsletter-section p {
        padding-left: 10px;
    }

    .footer-info-block {
        padding-right: 0;
        text-align: center;
        margin: 0;
    }

    .footer-info-block ul {
        display: flex;
        justify-content: center;
        gap: 20px;
        padding: 0;
        margin: 0;
    }

    .footer-newsletter-input {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }

    .footer-newsletter-input input[type="email"], .footer-newsletter-input button {
        border-radius: 5px;
    }
}