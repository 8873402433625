@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.blog {
    font-family: "SegoeUI";
    color: white;
}

@media (max-width: 599px) {
    .blog {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .blog.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

.blog.dark {
    font-family: "SegoeUI";
    color: white;
    background-color: black;
}

.blog.light {
    font-family: "SegoeUI";
    color: black;
    background-color: white;
}

.blog-header {
    position: relative;
    text-align: center;
    color: white;
}

.blog-header h1 {
    position: absolute;
    font-family: "RalewayHeavy";
    font-size: 60px;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: -1px -1px 5px rgb(0, 0, 0, 0.0),
                 1px -1px 5px rgb(0, 0, 0, 0.0),
                 -1px 1px 5px rgb(0, 0, 0, 0.3),
                 1px 1px 5px rgb(0, 0, 0, 0.3);
}

.blog-header p {
    position: absolute;
    font-size: 40px;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-shadow: -1px -1px 5px rgb(0, 0, 0, 0.0),
                 1px -1px 5px rgb(0, 0, 0, 0.0),
                 -1px 1px 5px rgb(0, 0, 0, 0.5),
                 1px 1px 5px rgb(0, 0, 0, 0.5);
}

.blog-header-background {
    width: 100%;
    height: 70vh;
    display: block;
    object-fit: cover;
}

@media (max-width: 1600px) and (min-width: 600px) {
    .blog-header p {
        font-size: 36px;
        top: 55%;
    }
}

.blog-icons {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    padding: 20px 50px;
}

.blog-icon {
    width: 30px;
    height: 30px;
}

.blog-articles {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.blog-article {
    width: 25%;
    padding: 20px;
    border-radius: 5px;
}

.blog-article h2{
    font-family: "PoppinsBold";
    font-size: 24px;
}

.blog-article img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.blog-article-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.blog-article-details strong {
    font-family: "PoppinsBold";
    font-size: 24px;
}

.blog-article-details p {
    font-size: 18px !important;
}

.blog-article-point {
    width: 5px !important;
    margin: 0 5px;
    padding-bottom: 2px;
}

.blog-article-share {
    height: 10px !important;
    width: 10px !important;
    padding-top: 0;
}

.blog-article p {
    font-size: 20px;
}

.blog-article-details img {
    width: 25px;
    border-radius: 50%;
}

.blog-article-bottom {
    font-size: 18px;
}

.blog-article-bottom a {
    font-size: 24px;
    font-family: "PoppinsBold";
    color: inherit;
}

.blog-article-image-container {
    position: relative;
}

.blog-article-date-container {
    position: absolute;
    top: 77%;
    left: 0px;
    background-color: white;
    padding: 5px;
    transform: rotate(-90deg);
    transform-origin: top left;
    white-space: nowrap;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.blog-article-date-container p {
    margin-bottom: 0;
    margin-top: 0;
}

.blog-show-more-container {
    text-align: center;
    padding: 50px 0;
}

.blog-show-more {
    display: inline-block;
    padding: 10px 40px; 
    background-color: #FFFFFF; 
    color: #121416;
    border: 1px solid blakc;
    border-radius: 3px;
    cursor: pointer;
    font-size: 20px;
    font-family: "RalewayHeavy";
    transition: background-color 0.3s ease;
}

.blog-show-more:hover {
    background-color: #ececec;
}

.blog-banner {
    position: relative;
    text-align: center;
    padding-bottom: 50px;
    color: black;
}

.blog-banner h2,
.blog-banner p,
.blog-banner button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
}

.blog-banner h2 {
    top: 30%;
    width: 80%;
    font-family: "RalewayHeavy";
    font-size: 48px;
}

.blog-banner p {
    top: 52%;
    font-size: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 0;
}

.blog-banner button {
    top: 70%;
    color: black;
    padding: 15px 80px;
    font-size: 20px;
    cursor: pointer;
}

.blog-suggestion-background {
    position: relative;
    width: 90%;
    height: 60vh;
    display: block;
    object-fit: cover;
    object-position: top;
    margin: 0 auto;
}

@media (max-width: 1400px) and (min-width: 600px) {
    .blog-banner h2 {
        top: 27%;
        width: 80%;
        font-family: "RalewayHeavy";
        font-size: 40px;
    }
}

@media (max-width: 599px) {
    .blog-header h1 {
        font-size: 50px;
    }
    
    .blog-header p {
        font-size: 24px;
        top: 60%;
    }
    
    .blog-header-background {
        object-fit: cover;
        object-position: right center;
    }

    .blog-icons {
        justify-content: center;
        gap: 30px;
        padding: 20px 20px;
    }

    .blog-articles {
        gap: 0px;
    }
    
    .blog-article {
        width: 90%;
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .blog-article.dark {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    }
    
    .blog-article.light {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    .blog-banner h2 {
        top: 20%;
        font-size: 24px;
    }
    
    .blog-banner p {
        top: 47%;
        font-size: 16px;
        width: 80%;
        padding-left: 0px;
        padding-right: 0px;
        margin: 0;
    }
    
    .blog-banner button {
        font-size: 16px;
    }

    .blog-suggestion-background {
        height: 40vh;
    }
    
}