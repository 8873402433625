@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

/* All --------------------------------------------------------*/
.service-page {
    width: 100%;
    font-family: "SegoeUI";
    position: relative;
    overflow: hidden;
}

@media (max-width: 599px) {
    .service-page {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .service-page.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

.service-page.dark {
    background-color: black;
    z-index: -2;
    color: white;
}

canvas#Matrix {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

canvas#LavaLamp {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.service-page.light {
    background-color: white;
    z-index: -2;
    color: black;
}

.service-header {
    position: relative;
    width: 100%;
}

.service-header-image {
    width: 100%;
    display: block;
}

.service-header-title {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 64px;
    text-align: center;
    font-family: "RalewayHeavy";
    text-shadow: -1px -1px 30px rgb(0, 0, 0, 0.3),
                 1px -1px 30px rgb(0, 0, 0, 0.3),
                 -1px 1px 30px rgb(0, 0, 0, 0.3),
                 1px 1px 30px rgb(0, 0, 0, 0.3);
}

.service-title h2 {
    text-align: center;
    font-family: "RalewayHeavy";
    font-size: 40px;
    padding-top: 1%;
    padding-bottom: 2%;
}

.service-title p {
    text-align: left;
    padding-left: 4%;
    padding-right: 4%;
    font-size: 28px;
}

.service-info-container {
    padding-left: 5%;
    padding-right: 5%;
}

.service-info h2 {
    font-family: "RalewayHeavy";
    font-size: 28px;
    padding-top: 10%;
    padding-bottom: 5%;
}

.service-info p {
    font-size: 22px;
}

.service-outro {
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 6%;
    padding-top: 4%;
}

.service-outro strong {
    display: block;
    text-align: center;
    font-size: 24px;
}

.vector-image1 {
    z-index: -1;
}

.vector-image2 {
    z-index: -1;
}

@media (max-width: 1050px) and (min-width: 600px) {
    .header-text {
        font-size: 46px;
    }

    .service-title h2 {
        font-size: 32px;
    }

    .service-title p {
        font-size: 20px;
    }

    .service-info h2 {
        font-size: 24px;
    }
    
    .service-info p {
        font-size: 16px;
    }

    .service-outro strong {
        font-size: 20px;
    }
}

@media (max-width: 599px) {
    .service-header-title {
        font-size: 40px;
        width: 100%;
    }

    .service-title h2 {
        font-size: 20px;
        text-align: center;
    }

    .service-title p {
        font-size: 16px;
        text-align: justify;
    }

    .service-info h2 {
        font-size: 22px;
    }
    
    .service-info p {
        font-size: 16px;
    }

    .service-outro strong {
        font-size: 18px;
    }

    .info-image {
        padding-top: 10%;
        width: 100%;
    }
}

/* Specif pages */
@media (min-width: 600px) {
    /* ArtWork Right --------------------------------------------- */
    .marketingStrategy-info-image, .webSiteDevelopment-info-image, .SEO-info-image,
    .UXDesign-info-image, .contentRedaction-info-image, .naming-info-image,
    .communicationSupport-info-image, .hardwareAdvice-info-image, .otherProjects-info-image {
        float: right;
        max-width: 45%;
        padding-left: 8%;
        padding-right: 7%;
        padding-bottom: 5%;
    }

    /* ArtWork Left --------------------------------------------- */
    .numericTransformation-info-image, .companyAdvice-info-image, .businessIntelligence-info-image,
    .SEA-info-image, .UIDesign-info-image, .visualIdentity-info-image, .communication-info-image,
    .infra-info-image, .personalSoftware-info-image {
        float: left;
        max-width: 45%;
        padding-left: 7%;
        padding-right: 8%;
        padding-bottom: 5%;
    }


    /* Marketing Strategy --------------------------------------------- */
    .marketingStrategy .vector-image1 {
        position: absolute;
        top: 20%;
        width: 35%;
        object-fit: cover;
    }

    .marketingStrategy .vector-image2 {
        position: absolute;
        bottom: 10%;
        width: 40%;
        object-fit: cover;
    }

    /* Numeric Transformation --------------------------------------------- */
    .numericTransformation .vector-image1 {
        position: absolute;
        top: 10%;
        right: 0%;
        width: 45%;
        object-fit: cover;
    }

    .numericTransformation .vector-image2 {
        position: absolute;
        left: 2%;
        bottom: 5%;
        width: 55%;
        object-fit: cover;
    }

    /* Company Advice --------------------------------------------- */
    .companyAdvice .vector-image1 {
        position: absolute;
        top: 5%;
        left: 0%;
        width: 55%;
        object-fit: cover;
    }

    .companyAdvice .vector-image2 {
        position: absolute;
        right: 2%;
        bottom: 5%;
        width: 55%;
        object-fit: cover;
    }

    /* Web Site Development --------------------------------------------- */
    .webSiteDevelopment .vector-image1 {
        position: absolute;
        top: 10%;
        left: 0%;
        width: 45%;
        object-fit: cover;
    }

    .webSiteDevelopment .vector-image2 {
        position: absolute;
        right: 2%;
        bottom: 0%;
        width: 55%;
        object-fit: cover;
    }


    /* Business Intelligence --------------------------------------------- */
    .businessIntelligence .vector-image1 {
        position: absolute;
        top: 12%;
        left: 25%;
        width: 50%;
        object-fit: cover;
    }

    .businessIntelligence .vector-image2 {
        position: absolute;
        right: -10%;
        bottom: -25%;
        width: 55%;
        object-fit: cover;
    }

    /* SEO --------------------------------------------- */
    .SEO .vector-image1 {
        position: absolute;
        top: 5%;
        right: -20%;
        width: 40%;
        object-fit: cover;
    }

    .SEO .vector-image2 {
        position: absolute;
        left: -10%;
        bottom: -25%;
        width: 55%;
        object-fit: cover;
    }

    /* SEA --------------------------------------------- */
    .SEA .vector-image1 {
        position: absolute;
        top: 15%;
        left: -10%;
        width: 60%;
        object-fit: cover;
    }

    .SEA .vector-image2 {
        position: absolute;
        left: 20%;
        bottom: -5%;
        width: 55%;
        object-fit: cover;
    }

    /* UXDesign --------------------------------------------- */
    .UXDesign .vector-image1 {
        position: absolute;
        top: -20%;
        right: -15%;
        width: 50%;
        object-fit: cover;
    }

    .UXDesign .vector-image2 {
        position: absolute;
        left: 10%;
        bottom: -15%;
        width: 70%;
        object-fit: cover;
    }

    /* UIDesign --------------------------------------------- */
    .UIDesign .vector-image1 {
        position: absolute;
        top: 15%;
        left: -10%;
        width: 60%;
        object-fit: cover;
    }

    .UIDesign .vector-image2 {
        position: absolute;
        left: 10%;
        bottom: -15%;
        width: 70%;
        object-fit: cover;
    }

    /* Content Redaction --------------------------------------------- */
    .contentRedaction .vector-image1 {
        position: absolute;
        top: 22%;
        left: 10%;
        width: 40%;
        object-fit: cover;
    }

    .contentRedaction .vector-image2 {
        position: absolute;
        right: 0%;
        bottom: -20%;
        width: 70%;
        object-fit: cover;
    }

    /* Visual Identity --------------------------------------------- */
    .visualIdentity .vector-image1 {
        position: absolute;
        top: 20%;
        right: 0%;
        width: 60%;
        object-fit: cover;
    }

    .visualIdentity .vector-image2 {
        position: absolute;
        left: 0%;
        bottom: -15%;
        width: 40%;
        object-fit: cover;
    }

    /* Naming --------------------------------------------- */
    .naming .vector-image1 {
        position: absolute;
        top: 5%;
        right: 2%;
        width: 50%;
        object-fit: cover;
    }

    .naming .vector-image2 {
        position: absolute;
        left: 0%;
        bottom: 2%;
        width: 40%;
        object-fit: cover;
    }

    /* Communication --------------------------------------------- */
    .communication .vector-image1 {
        position: absolute;
        top: 30%;
        right: 0%;
        width: 60%;
        object-fit: cover;
    }

    .communication .vector-image2 {
        display: none;
    }

    /* Communication Support --------------------------------------------- */
    .communicationSupport .vector-image1 {
        position: absolute;
        top: 30%;
        left: 0%;
        width: 40%;
        object-fit: cover;
    }

    .communicationSupport .vector-image2 {
        position: absolute;
        right: 2%;
        bottom: 5%;
        width: 40%;
        object-fit: cover;
    }

    /* Infra --------------------------------------------- */
    .infra .vector-image1 {
        position: absolute;
        top: 15%;
        right: 0%;
        width: 40%;
        object-fit: cover;
    }

    .infra .vector-image2 {
        position: absolute;
        left: 10%;
        bottom: 5%;
        width: 30%;
        object-fit: cover;
    }

    /* Hardware Advice --------------------------------------------- */
    .hardwareAdvice .vector-image1 {
        position: absolute;
        top: 30%;
        left: 0%;
        width: 40%;
        object-fit: cover;
    }

    .hardwareAdvice .vector-image2 {
        position: absolute;
        right: 0%;
        bottom: 5%;
        width: 30%;
        object-fit: cover;
    }

    /* Personal Software --------------------------------------------- */
    .personalSoftware .vector-image1 {
        position: absolute;
        top: 10%;
        right: 0%;
        width: 80%;
        object-fit: cover;
    }

    .personalSoftware .vector-image2 {
        position: absolute;
        left: 0%;
        bottom: 0%;
        width: 40%;
        object-fit: cover;
    }

    /* Other Projects --------------------------------------------- */
    .otherProjects .vector-image1 {
        position: absolute;
        top: 30%;
        left: 0%;
        width: 40%;
        object-fit: cover;
    }

    .otherProjects .vector-image2 {
        position: absolute;
        right: 0%;
        bottom: 10%;
        width: 30%;
        object-fit: cover;
    }
}