@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.achievements {
    text-align: center;
}

.achievements.dark {
    background-color: black;
}

@media (max-width: 599px) {
    .achievements {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .achievements.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

/* achievements 1 ----------------------- */
.achievements-section1 {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.achievements-agency-background {
    position: relative;
    width: 100%;
    height: 60vh;
    display: block;
    object-fit: cover;
    object-position: top;
}

.achievements-introduction {
    height: 40vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.achievements-introduction.dark {
    background-color: black;
    color: white;
}

.achievements-introduction.light {
    background-color: white;
    color: black;
}

.achievements-introduction-title {
    margin: 0;
    font-family: "RalewayHeavy";
    font-size: 50px;
}

.achievements-introduction-body {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 35px;
    font-family: "SegeoUI";
}

@media (min-width: 600px) and (max-width: 1400px) {
    .achievements-introduction-body {
        font-size: 28px;
    }

    .achievements-introduction-title {
        font-size: 42px;
    }
}

@media (max-width: 599px) {
    .achievements-introduction {
        margin: 40px 0;
        height: auto;
        width: 90%;
    }

    .achievements-introduction-body {
        font-size: 16px;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
    }

    .achievements-introduction-title {
        font-size: 30px;
    }

    .achievements-agency-background {
        width: 100%;
        height: 30vh;
    }
}

/* achievements 2 ----------------------- */

.achievements-section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap: 50px;
    padding-bottom: 100px;
}

.achievements-section2-line {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.achievements-section2-line:hover .achievements-container {
    filter: brightness(40%);
    transition: filter 1s;
}

.achievements-section2-line .achievements-container:hover {
    filter: brightness(100%);
    transform: scale(1.05);
    z-index: 1;
    transition: filter 0.5s, transform 0.5s;
}

.achievements-container {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

@media (max-width: 599px) {
    .achievements-section2-line {
        flex-direction: column;
        width: 100%;
        gap: 50px;
    }

    .achievements-container {
        width: 80%;
        height: auto;
    }

    .achievements-section2:hover .achievements-container {
        filter: none;
        transform: none;
        transition: none;
    }

    .achievements-section2 .achievements-container:hover {
        filter: none;
        transform: none;
        z-index: auto;
        transition: none;
    }    
}

