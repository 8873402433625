@font-face {
    font-family: "SegoeUI";
    src: url("../../font/Segoe_UI.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "RalewayHeavy";
    src: url("../../font/Raleway_Heavy.ttf") format("truetype");
    font-display: swap;
}

.about {
    width: 100%;
    font-family: "SegoeUI";
}

@media (max-width: 599px) {
    .about {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .about.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

.about-header {
    position: relative;
    height: 60vh;
    text-align: center;
    padding-bottom: 200px;
    background-color: #8F6E5D;
}

.about-header h2 {
    margin: 0;
    font-size: 40px;
    color: white;
}

.about-logo {
    width: 50vw;
    height: 50vh;    
    margin-top: 0px;
}

.about-newsletter-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    margin-top: 20px;
}

.about-newsletter-input input[type="email"] {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    background-color: rgb(212, 152, 122);
    color: white;
    transition: background-color 0.3s ease;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.about-newsletter-input input[type="email"]:focus {
    background-color: #B29688;
}

.about-newsletter-input input[type="email"]::placeholder {
    color: rgb(37, 37, 37); 
}

.about-newsletter-input button {
    padding: 10px 15px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    background-color: #775340;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.about-newsletter-input button:hover {
    background-color: #002530;
}

.about-newsletter-input button:focus {
    outline: none;
}

.about-blocks.dark, .about-final-blocks.dark {
    background-color: black;
    color: white;
}

.about-blocks.light, .about-final-blocks.light {
    background-color: white;
    color: black;
}

.about-blocks .about-block {
    display: flex;
    width: 100%;
}

.about-blocks {
    overflow-y: auto;
    overflow-x: hidden;
}

.about-block {
    height: 100vh;
    display: flex;
    align-items: center;
}

.about-image-background {
    background-size:50%;
    background-position: right;
    background-attachment: fixed;
    height: 100%;
}

.about-image-background-square {
    background-size:75%;
    background-position: left;
    background-attachment: fixed;
    height: 100%;
}

.about-block.left .about-image-background, .about-block.right .about-block-content {
    order: 1;
    width: 50%;
    text-align: right;
}

.about-blocks .about-image-background, .about-block.left .about-block-content {
    order: 2;
    width: 50%;
    text-align: left;
}

.about-block-content {
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
}

.about-block-content h1, .about-final-block-content h1, .about-block-content h2, .about-final-block-content h2 {
    font-family: "RalewayHeavy";
    font-size: 50px;
    padding-bottom: 20px;
}

.about-block-content p, .about-final-block-content p {
    font-size: 30px;
}

.about-presentation-banner {
    width: 100%;
    text-align: center;
    padding: 30px 0;
    background-color: #8F6E5D;
    color: white;
    font-size: 64px;
    font-family: "RalewayHeavy";
    letter-spacing: 80px;
}

.about-final-blocks .about-final-block {
    display: flex;
    width: 100%;
}

.about-final-blocks {
    overflow-y: auto;
    overflow-x: hidden;
}

.about-final-block {
    height: 100vh;
    display: flex;
    align-items: center;
}

.about-final-block.left .about-image-background, .about-final-block.left .about-image-background-square, .about-final-block.right .about-final-block-content {
    order: 1;
    width: 50%;
    text-align: left;
}

.about-final-blocks .about-image-background, .about-final-blocks .about-image-background-square, .about-final-block.left .about-final-block-content {
    order: 2;
    width: 50%;
    text-align: left;
}

.about-final-block-content {
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
}

.about-final-block-content h1, .about-final-block-content h2 {
    text-align: center;
}

@media (max-width: 1600px) and (min-width: 600px) {
    .about-block-content h1, .about-final-block-content h1, .about-block-content h2, .about-final-block-content h2 {
        font-size: 42px;
    }
    
    .about-block-content p, .about-final-block-content p {
        font-size: 26px;
    }

    .about-presentation-banner {
        letter-spacing: 60px;
    }
}

@media (max-width: 599px) {
    .about-logo {
        width: 100%;
        height: 50%;
        margin-top: 50px;
    }

    .about-block, .about-final-block {
        flex-direction: column;
    }    

    .about-block.left .about-image-background,
    .about-block.right .about-image-background,
    .about-final-block.left .about-image-background,
    .about-final-block.left .about-image-background-square,
    .about-final-block.right .about-image-background,
    .about-final-block.right .about-image-background-square {
        order: unset;
        width: 100%;
        background-size: cover;
        background-attachment: unset;
        height: 50vh;
    }

    
    .about-block.left .about-block-content,
    .about-block.right .about-block-content,
    .about-final-block.left .about-final-block-content,
    .about-final-block.right .about-final-block-content {
        width: 100%;
        padding: 20px;
        height: 50vh;
        text-align: left;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .about-block-content h1, .about-final-block-content h1, .about-block-content h2, .about-final-block-content h2 {
        text-align: center;
    }

    .about-block-content p, .about-final-block-content p {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
    }

    .about-block-content h1, .about-final-block-content h1, .about-block-content h2, .about-final-block-content h2 {
        font-size: 28px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .about-presentation-banner {
        font-size: 40px;
        letter-spacing: 10px;
    }
}